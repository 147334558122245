import { Navigate, useRoutes } from 'react-router-dom';
import { useSearchParams } from 'src/routes/hook';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//

import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { profileRoutes } from './profile';
import { pricingRoutes } from './pricing';
import { onboardingRoutes } from './onboarding';
import { successRoutes } from './success';
import { optionsRoutes } from './options';
import { createCampRoutes } from './create-capm';

// ----------------------------------------------------------------------

export default function Router() {
  const searchParams2 = useSearchParams();
  const ref = searchParams2.get('ref');

  return useRoutes([
    {
      path: '/',
      element: ref ? (
        <Navigate to={`${PATH_AFTER_LOGIN}?ref=${ref}`} replace />
      ) : (
        <Navigate to={PATH_AFTER_LOGIN} replace />
      ),
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Profile
    ...profileRoutes,

    // Pricing
    ...pricingRoutes,

    // Onboarding
    ...onboardingRoutes,

    // Success
    ...successRoutes,

    //
    ...optionsRoutes,

    //
    ...createCampRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
