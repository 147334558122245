import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    login: '/api/users/login',
    register: '/api/users/register',
    verifyMail: '/api/users/verify-mail',
    resendMail: '/api/users/query-verify-mail',
    setPassword: '/api/users/set-password',
    resetPassword: '/api/users/reset-password',
    googleAuth: '/api/users/google_auth',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
    create: '/api/posts/create',
    statistic: '/api/posts/read',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  strategies: {
    audience: '/api/audience',
    download: '/api/projects/download-file',
    editMsgs: '/api/message/edit',
    getStrategies: '/api/strategies',
    create: '/api/strategy/create',
    regenerate: '/api/strategy/regenerate',
    save: '/api/strategy/save',
    editStrategyName: '/api/strategy/edit',
    count: '/api/strategy/count',
    accounts: '/api/strategy/accounts',
    segments: '/api/strategy/segments',
    saveSegment: '/api/strategy/segment/save',
    regenerateSegment: '/api/strategy/segment/regenerate',
    approveSegment: '/api/strategy/segment/approve',
    postWebsite: '/api/strategy/segment/website',
    analyzeURL: '/api/strategy/website',
  },
  posts: {
    list: '/api/posts/list',
    generate: '/api/posts/create',
    edit: 'api/posts/edit',
    uploadImage: 'api/projects/upload-file',
    displayImage: '/api/projects/files/',
    count: '/api/posts/count',
    getSettings: '/api/posts/parameters',
    saveSettings: '/api/posts/parameters',
    verifySite: 'api/posts/site/verify',
    getPersonalStyles: 'api/posts/parameters/style',
    savePersonalStyle: 'api/posts/parameters/style',
    generateImage: 'api/posts/image',
  },
  users: {
    checkUser: '/api/users/info',
    edit: '/api/users/edit/profile',
    editPassword: 'api/users/edit/password',
    delete: 'api/users/delete',
    skipOnboarding: 'api/users/skip_onboarding',
    checkConsents: '/api/users/check-consents',
    sendConsent: '/api/users/set-consents',
    accounts: '/api/users/accounts',
    saveTips: 'api/users/set-tips',
  },
  subscriptions: {
    cancel: 'api/stripe/subscription/cancel',
    invoices: 'api/stripe/charges',
    resume: 'api/stripe/subscription/resume_last',
    payment: 'api/stripe',
    getAttributes: 'api/stripe/get-attr',
    getPortalUrl: 'api/stripe/create-portal',
  },
  notifications: {
    get: 'api/users/notification/get',
    set: 'api/users/notification/set',
  },
  socialMediaConnect: {
    connect: '/api/channels/connect',
    disconnect: '/api/channels/disconnect',
    getAuthUrl: '/api/channels/get_auth_url',
    savePlace: '/api/channels/place/save',
    listPlaces: '/api/channels/list-places',
  },
  analytics: {
    mp: 'api/mp',
  },
  lg: {
    getDetails: '/api/lg/campaign',
    getCampaigns: '/api/lg/campaigns',
    sender: 'api/lg/sender',
    thread: 'api/lg/thread',
  },
};
