import { useState, useEffect, useCallback } from 'react';
import Collapse from '@mui/material/Collapse';
import { usePathname } from 'src/routes/hook';
import { useActiveLink } from 'src/routes/hook/use-active-link';
import { useActiveSubLink } from 'src/routes/hook/use-active-sub-link';
import { Stack } from '@mui/material';
import { NavListProps, NavConfigProps } from '../types';
import NavItem from './nav-item';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
  subs?: boolean;
  changeStatuses?: (idx: number, newStatus: boolean) => void;
  index: number;
};

export default function NavList({
  data,
  depth,
  hasChild,
  config,
  subs,
  changeStatuses,
  index,
}: NavListRootProps) {
  const pathname = usePathname();

  const active1 = useActiveLink(data.path, hasChild);

  const activeSubs1 = useActiveSubLink(data.id);

  const active = subs ? activeSubs1 : active1;

  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(active);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (changeStatuses && !subs) {
      if (active === true && hasChild) {
        changeStatuses(index, true);
      } else {
        changeStatuses(index, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        externalLink={externalLink}
        config={config}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList
            data={data.children}
            depth={depth}
            config={config}
            value={value}
            setValue={setValue}
          />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
  value: string;
  setValue: (value: string) => void;
};

function NavSubList({ data, depth, config, value, setValue }: NavListSubProps) {
  return (
    <Stack>
      {data.map((list, index) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
          subs
          index={index}
        />
      ))}
    </Stack>
  );
}
