import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { hideScroll } from 'src/theme/css';
import { NavSectionMini } from 'src/components/nav-section';
import NewLogo from 'src/components/logo/new-logo';
import { LoadingButton } from '@mui/lab';
import { useRouter } from 'src/routes/hook';
import { useAuthContext } from 'src/auth/hooks';
import { useEffect } from 'react';
import { getStrategies } from 'src/utils/api';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';
import AccountPopoverVertical from './account-popover-vertical';

export default function NavMini() {
  const { user, setStrategiesContext } = useAuthContext();

  const navData = useNavData();

  const { logout, strategiesContext } = useAuthContext();

  const router = useRouter();

  const currentPath = window.location.pathname;

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getStrategies();
        setStrategiesContext(res.data.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (currentPath !== '/strategies' && strategiesContext.length === 0) {
      getData();
    }
  }, [currentPath, setStrategiesContext, strategiesContext.length]);

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: NAV.W_MINI,
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 17,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <NewLogo />

        <NavSectionMini
          data={navData}
          sx={{ marginTop: '16px' }}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
          }}
        >
          <AccountPopoverVertical />

          <Box width="100%">
            <LoadingButton
              onClick={handleLogout}
              sx={{ width: '100%', color: 'error.dark', paddingLeft: '0px', paddingRight: '0px' }}
            >
              <span>Log out</span>
            </LoadingButton>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
